/* General styles for the slider */
.slider-container {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
    margin-top: 90px;
  }
  
  /* Each slide takes up full width and height */
  .slide {
    width: 100%;
  }
  
  .slide-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  /* Custom arrow buttons */
  .custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    background-color: white;
    color: rgb(119, 101, 255);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
  }
  
  .custom-next {
    right: 10px;
  }
  
  .custom-prev {
    left: 10px;
  }
  
  /* Responsive design */
  @media screen and (max-width: 768px) {
    .custom-arrow {
      font-size: 1.5rem;
      padding: 5px;
    }
  }
  

  /* Custom styling for the dots */
.slick-dots {
    display: flex !important;
    justify-content: center;
    list-style: none;
    padding: 0;
  }
  
  .slick-dots li {
    margin: 0 5px;
  }
  
  .slick-dots li button {
    font-size: 0; /* Hides default number in button */
  }
  
  .slick-dots li .custom-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid rgb(119, 101, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .slick-dots li.slick-active .custom-dot {
    background-color: rgb(119, 101, 255);
    color: white;
  }
  