@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.footer {
    display: flex;
    justify-content: space-between;
    padding: 20px 100px;
    font-size: 1.1em;
    align-items: center;
    color: white;
    background-color: #000518;
    font-family: "Open Sans", sans-serif;
  }
  
  .footer div {
    /* background-color: #00bbbb; */
    width: 200px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    font-size: 35px;
  }
  .footer .icon{
    cursor: pointer;
    color: rgb(255, 255, 255);
 
  }
  .footer .icon:hover{
    color: rgb(224, 209, 255);
  }
  
  
  @media only screen and (max-width: 700px) {
  
    .footer {
       flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      /* gap: 22px; */
      padding: 10px 15px;
      font-size: 1em;
    }
    .footer div {
        margin: 10px;
      gap: 18px;
      justify-content: center;
    }
  }