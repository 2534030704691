.addmi{
   /* border: 1px solid grey; */
   /* background-color: rgb(237, 255, 243); */
   padding: 10px;
   border-top: 1px solid grey;
   border-radius: 16px;
    padding-bottom: 15px;
   margin: 10px;
   display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
}
.addmi h2{ 
    padding: 15px 0px;
    padding-bottom: 5px;
   /* color: rgb(19, 214, 204); */
   color: rgb(50, 50, 226);
    /* border-bottom: 1px solid rgb(32, 47, 216); */
    width: 60%;
   display: grid;
   place-items: center;
   /* box-shadow: 0px 3px 5px rgb(171, 171, 180); */
}
body{
    margin: 0;
    padding: 0;
}
.addmi ul li{
    margin: 10px;
    margin-top: 19px;
    font-size: 17px;
    color: rgb(0, 0, 0);
}
@media only screen and (max-width: 700px) {
   .addmi{
      position: relative;
      top: 10px;
   }
   .footer{
    margin-top: 90px;
   }
   .addmi h2{
    font-weight: 500;
    /* color: rgb(0, 170, 255); */
    color: rgb(52, 64, 197);
      width: auto;
      padding-bottom: 10px;
   }
   .addmi ul li{
    margin: 10px;
    margin-top: 10px;
    font-size: 15px;
    color: rgb(0, 0, 0);
   }
}
  

