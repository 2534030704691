@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.nav {
    height: 75px;
    width: 100%;
    /* background-color: #edecf9; */
    background-color: #ffffff;
    /* background-color: #8C52FF; */
    position: absolute;
    position: fixed;
    top: 0;
    z-index: 110;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 1px 3px rgb(206, 206, 206);
    /* border-bottom: 1px solid rgb(161, 161, 161); */
  }
  .open {
    /* visibility: hidden; */
    display: none;
  }
  
  .nav img {
    height: 70px;
    /* width: 100%; */
    max-width: 320px;
    /* border: 1px solid blue; */
  }
  
  :any-link {
    /* font-weight: 500; */
    text-decoration: none;
    color: #6223de;
    font-family: "Roboto", sans-serif;
    font-weight: 430;
  font-style: normal;
    /* font-weight: 500; */
  }
  :any-link:hover {
    color: rgb(2, 182, 214);
    /* color: rgb(26, 62, 225); */
  }

@media only screen and (max-width: 1000px) {
    .nav ul li {
      /* background-color: #00bbbb; */
      display: none;
    }
  
    .nav {
      height: 70px;
      position: fixed;
      /* background-color: #8C52FF;  */
    }
    .nav ul {
      width: 100%;
    }
    .main {
      margin-top: 100px;
    }
  
    .nav img {
      width: 200px;
      height: 50px;
    } 
     #open {
      /* visibility: visible; */
      display: block;
      position: fixed;
      right: 15px;
  
    }
  
  }

  .outer {
    margin: 0px;
    padding: 0px;
  
  }
  
  .heading {
    /* background-color: cornflowerblue; */
    background: transparent;
    display: flex;
    margin-left: 20px;
    /* gap: 10px; */
    justify-content: center;
    align-items: center;
    /* margin-left: 50px; */
    /* width: 300px; */
  }


  .slider {
    height: 100%;
    width: 0%;
    background-color: #efffff;
    /* border: 2px solid red; */
    position: absolute;
    z-index: 50;
    right: 0;
    top: 70px;
    position: fixed;
    display: grid;
    place-items: center;
  }
  
  .slider ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    text-decoration: none;
    font-size: 27px;
    font-family: sans-serif;
  }
  
  .slider ul li {
    padding: 20px 0px;
  }
  
  .nav ul {
    /* margin: 0; */
    height: 50px;
    display: flex;
    /* width: 100%; */
    /* justify-content: space-between; */
    align-items: center;
    list-style-type: none;
    text-decoration: none;
    font-size: 18px;
    font-family: sans-serif;
    /* background-color: rgb(0, 255, 255); */
    background: transparent;
    /* flex-wrap: nowrap; */
    gap: 10px;
    margin: 10px 0px;
    color: white;
    margin-right: 20px;
    /* margin-right: 50px; */
  
  }
  
  .nav ul li {
    padding: 10px 5px;
    margin: 0 10px;
  }
  
  ::-webkit-scrollbar {
  
    display: none
  }  