/* ImageOverlay.css */

/* Outer container with Flexbox for responsive behavior */
.img-box-out {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    gap: 4%;
  }
  
  .image-container {
    position: relative;
    width: 100%;
    max-width: 350px; /* Maximum width of the image */
    height: auto;
    aspect-ratio: 3 / 2; /* Maintain the aspect ratio (600x400) */
    overflow: hidden; /* Ensures content stays within the container */
    border-radius: 5px;
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(66, 31, 225, 0.2); /* Dark overlay effect */
    background: linear-gradient(to top, rgba(0, 0, 0, 0.566), rgba(255, 255, 255, 0));
    z-index: 2;
  }
  
  .text-overlay {
    position: absolute;
    bottom: 40%;
    left: 0;
    width: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); Semi-transparent background for text */
    color: white;
    /* padding: 10px; */
    text-align: center;
    z-index: 3; /* Ensure the text stays above the overlay */
    font-size: 20px;
    font-weight: 600;
  }
  
  /* Responsive behavior for smaller screens */
  @media (max-width: 768px) {
    .image-container {
      max-width: 100%; /* Image takes full width on small screens */
    }
    
    .text-overlay {
      font-size: 14px; /* Adjust font size for smaller screens */
    }
  }
  