@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
.contactus {
    position: relative;
    z-index: -1;
    /* top: 10px; */
    padding-top: 10px;
    text-align: center;
    background-color: #ffffff;
    overflow-x: hidden;
}
.form-brainfiles{
   display: flex;
   justify-content: center;
/* background-color: darksalmon; */
height: auto;
}
.contact-2 {
  background-color: #ffffff;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    height: auto;
    width: 100%;
    font-family: "Open Sans", sans-serif;
}
.contact-cont{
  margin-top: 80px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  width: auto;
  height: auto;
  gap: 10px;
}
@media only screen and (max-width: 1000px) {
  .contact-cont{
    flex-wrap: wrap;
    background-color: #ffffff;
  }
  .form-brainfiles{
   margin-top: 25px;
 }

}

.contact-phone-email{
  display: flex;
  gap: 10px;
  align-items: flex-start;
  /* border: 2px solid black; */
}
.contact-detail-brain{
  background-color: rgb(237, 237, 237);
  border-radius: 10px;
  width: 75%;
  padding: 15px;
  display: flex;
flex-direction: column;
gap: 10px;
}
.contact-icons{
 /* border: 2px solid aqua; */
 display: flex;
 justify-content: left;
 align-items: center;
 gap: 5px;
 width: 100px;
}
.map{
    margin: 15px;
    height: 500px;
}

.contact-address {
  margin-left: 7px;
}
.para {
  text-align: center;
  border-bottom: 1px solid grey;
}
.para span {
  font-size: 20px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}
.iframe {
  height: 100%;
  width: 100%;
  border: none;
}
.first-nest {
  margin-top: 100px;
  height: 200px;
  width: 300px;
  background-color: #00bbbb;
}
.infox{
  cursor: pointer;
  border: 1px solid rgb(184, 184, 184);
  box-shadow: 0px 0px 30px rgba(105, 104, 104, 0.5);
  background-color:#8C52FF;
  padding: 20px 30px;
  text-align: center;
  border-radius: 15px;
  margin: auto;
 max-width: 500px;
  margin-bottom: 20px;
  transition: all 400ms;
}
.infox:hover{
  background-color:#6b31dd;
  box-shadow: none;
}
.info2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.infox h1 {
  border-bottom: 1px solid rgb(116, 240, 225);
  border-radius: 10px;
  color: white;
}
.exp {
  /* background-color: #D8B5FF; */
  padding: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: white;
}
.exp p {
  margin: 7px;
}
.profile .upper-details {
  /* height: 140px; */
  padding: 10px;
  /* background-color: #c7fff7; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-left: 20px;
}
.profile .phone {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile {
  width: auto;
  height: auto;
  background: transparent;
  color: white;
  /* background-color: #e9f5ff; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 10px;
}

.dp {
  height: 140px;
  width: 140px;
}
.dp img {
  height: 140px;
  width: 140px;
  border-radius: 50%;
}



.outerForm {
  min-width: 600px;
    margin: 20px 0px;
}
.formHead p {
    text-align: center;
}
.formHead{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.formHead span {
    text-align: center;
    /* background-color: antiquewhite; */
    font-size: 1.3em;
    font-family: Arial, Helvetica, sans-serif;
}
.main-form{
  /* border: 1px solid black; */
    margin: 10px 20px;
    padding: 0px 30px;
    border-radius: 26px;
  }
  .inside-form{
 /* width: 100%; */
    /* padding: 5px 10%; */
    display: flex;
    flex-direction: column;
   align-items: center;
  }
  .Query-input{
     outline: none;
     border: none;
     font-size: 16px;
  }
  .main-form input[type="text"]{
    width: 95%;
    padding: 13px;
    border: 1px solid #555555;
    border-radius: 4px;
    margin-top: 10px;
  }
  .main-form input:focus{
   border: 2px solid rgb(0, 0, 0);
  }
  .main-form select{
    width: 100%;
    padding: 13px;
    border: 1px solid #787878;
    border-radius: 4px;
    margin-top: 10px;
  }
  .submit{
      /* border: 1px solid black; */
       width: 100%;
      padding: 13px;
}
.submit input{
  cursor: pointer;
  border: 2px solid rgb(255, 255, 255);
  color: white;
  height: 50px;
  padding: 10px;
  width: 100%;
  font-size: 1.3em;
 background-color: #000000;
}
.submit input:hover{
  color: #8c69ff;
}
@media only screen and (max-width: 600px){
  .contact-detail-brain{
    width: 90%;
  }
}
  @media only screen and (max-width: 1000px) {
    .outerForm {
      margin: 5px;
      min-width: auto;
  }
  .profile .upper-details{
    align-items: center;
  }
    .contact-page{
      flex-wrap: wrap;
      gap: 0px;
      padding: 0 ;
    }
    .contact-2{
      display: flex;
      flex-direction: column;
      padding: 0px;
    }
    .contact-1{
      width: 100%;
    }
    .contactus{
      top: 80px;
    }
      .main-form{
        border: none;
          margin: 0px;
          padding: 0px ;
          border-radius: 26px;
        }
      .Query-input{
        margin: 4px;
        outline: none;
        border: none;
        font-size: 14px;
        padding: 2px 5px;
        background-color: #ffffff;
     }
     .main-form input[type="text"]{
      width: 92%;
      height: 16px;
      }
  .para{
    text-align: center;
    padding: 5px;
  }
   }

   @media only screen and (max-width: 700px){
    .infox{
      /* margin-top: 460px; */
      box-sizing: border-box;
      width: 90vw;
      margin-bottom: 0px;
    }
   }


   