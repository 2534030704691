.pyq-outbox{
    background-color: #ffffff;
    background-image: url("pyq-back-2.png");
    background-size: 100% 100vh;
    background-attachment: fixed;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
}
.pyq-outbox h1{
    text-align: center;
    color: rgb(1, 43, 42);
}
.pyq-outbox p{
    font-size: 19px;
    text-align: center;
}
.pyq-head {
    position: sticky;
    top: 70px;
    background-color: rgb(255, 255, 255); /* Fallback color */
  background-color: rgba(255, 255, 255, 0.92); /* Black w/opacity/see-through */
    padding: 20px; 
    z-index: 1; 
  }
.kartik-box-hai{
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    gap: 70px;
    padding: 10px 100px;
    background-color: transparent;
}
.main-pyq-box{
   max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 40px;
}
.exam-box{
    width: 330px;
    text-align: center;
    margin: 5px;
    padding: 10px;
    background-color: #5f26d0;
    border: 2px solid #ffffff;
    border-radius: 20px;
}

@media only screen and (max-width: 1000px) {
    .exam-box{
        width: 280px;
    }
}


.exam-box h4{
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    margin: 5px;
    border-bottom: 1px solid rgb(255, 255, 255);
    color: #ffffff;
}
.paper-box{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
}
.paper{
    border: none;
    background-color: rgb(255, 255, 255);
    color: white;
    font-size: 16px;
    width: 80%;
    padding: 10px;
    border-radius: 20px;
}

@media only screen and (max-width: 1000px) {
   .mani{
    padding: 10px 20px;
   }
   .pyq-outbox{
    top: 75px;
    /* background-image: url("pyq-back-3.png"); */
}
.pyq-outbox p{
    font-size: 17px;
    text-align: center;
}
.pyq-head {
    padding: 10px; 
  }

}
