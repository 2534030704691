@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
.head {
  background-color: #e1fffa;
  text-align: center;
  margin-top: 60px;
}
.head h1 {
  margin-top: 20px;
}
.second {
  display: flex;
  flex-direction: column;
}
.out-box-2 {
  /* padding: 5% 10%; */
  padding: 5% 0 5% 10%;
  background-color: #e1fffa;
}
.out-box-1 {
  /* padding: 5% 10%; */
  padding: 5% 10% 5% 0;
  background-color: #e1fffa;
}
.monitor {
  height: 100px;
  background-color: cadetblue;
}
.Contact {
  height: 550px;
  /* width: 100%; */
  background-color: #D8B5FF;
  padding: 5% 10%;
}
.form {
  width: 50%;
  height: 450px;
  border: 2px solid black;
}
.contact-bigbox {
  padding: 20px;
  height: 500px;
  width: 97%;
  background-color: #98fff0;
  display: flex;
  gap: 7%;
  justify-content: center;
  align-items: center;
}
.home {
  display: flex;
  flex-direction: column;
  height: auto;
  /* gap: 0; */
}
.main {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 20px;
  gap: 60px;
  background-color: #8c52ff;
  margin-top: 70px;
  /* background: url("welcome.png") no-repeat right top;  */
   background-image: url("../../public/ans-8.png");
  background-repeat: no-repeat;
 background-size: 100% 100%;
 height: auto;
}
.success-page h1{
  color: rgb(105, 25, 11);
  text-align: center;
  margin: 10px;
  margin-top: 100px;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
}
.rightdiv {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;  
  gap: 100px;
  padding: 30px;
}
.icons {
  font-size: 40px;
}
.rigthdivcard:hover {
  box-shadow: 0px 0px 7px rgb(105, 105, 105);
}
.rigthdivcard {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  border: 2px solid rgb(234, 236, 230);
  width: 150px;
  border-radius: 8px;
  padding: 20px;
  gap: 14px;
  /* height: 120px; */
  transition: all 0.5s ease;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}
.welcome-note {
  height: 490px;
  padding: 30px 60px;
  text-align: center;
  width: 44%;
/* background-color: #52ffee; */
font-family: Arial, Helvetica, sans-serif;
margin-top: 20px;
margin-left: 60px;
}
.welcome-note h1 {
  margin: 10px;
  /* margin-top: 30px; */
  font-size: 3vw;
}
.about-main {
  background-color: #8C52FF;
  display: flex;
  justify-content: center;
  height: auto;
  /* height: 90vh; */
  border-bottom: 4px solid #8C52FF;
}
.about-box {
  padding: 10px;
  /* background-color: rgb(64, 126, 111); */
  
  color: rgb(0, 0, 0);
font-family: Arial, Helvetica, sans-serif;
background-image: url("about-back-2.png");
background-size: 100% 100%;
  /* background-color: #f0f6ff; */
  transition: all 400ms;
}
.about-box:hover{
  color: rgb(255, 255, 255);
  background-color: #8C52FF;
  background-image: none;
}
.about-content p {
  font-size: 21px;
  word-spacing: 3px;
  font-family: "Mukta", sans-serif;
 
}
.about-content {
  /* border: 1px solid red; */
  background: transparent;
  padding: 5px 10%;
  border-radius: 5%;
}
.about-content h1 {
  margin-left: 15px;
  margin: 0px;
  margin-bottom: 20px;
  margin-top: -20px;
}
.welcome-note h2 {
  font-size: 28px;
  color: rgb(104, 85, 198);
  margin: 50px 0px;
  /* color: #0c8997; */
}
.welcome-note p {
  margin: 10px;
  margin-top: 40px;
  font-size: 16px;
  text-align: left;
  /* letter-spacing: 1px; */
  word-spacing: 3px;
  font-family: "Roboto", sans-serif;
  margin-left: 35px;
  line-height: 23px;

}
.rightdiv h4{
  color: rgb(22, 57, 213);
  font-size: 20px;
}
.icon-1{
  font-size: 45px;
}


@media only screen and (max-width: 1000px) {

  .out-box-1 {
    padding: 0;
  }

  .about-main {
    padding: 0;
    border: none;
    box-shadow: none;
    /* margin: 0; */
    /* background-color: #dafef6; */
  }

  .about-box {
    background-image: none;
    background-color: #8C52FF;
    color: white;
    padding: 20px;
    display: flex;
    justify-content: center;
    /* background-color: transparent; */
    box-shadow: none;
    height: auto;
    margin: auto;
    width: auto;
  }

  .about-content {
    width: auto;
    padding: 0;
    margin: 0;
    height: auto;
  }

  .about-content p {
    font-size: 18px;
    text-align: justify;
    padding: 0;
    margin: 0;
  }

  .about-content h1 {
    text-align: center;
    font-size: 26px;
    /* border-bottom: 1px solid black; */
  }

  .out-box-2 {
    padding: 0;
  }

  .rightdiv {
    display: flex;
    justify-content: center;
     
    gap: 20px;
  }

  .icons {
    font-size: 25px;
    color: blue;
  }
  .icon-1{
    font-size: 40px;
  }
  .rigthdivcard {
    border: 1px solid rgb(198, 198, 198);
    width: 100px;
    border-radius: 8px;
    padding: 20px;
    gap: 7px;
    box-shadow: 0 0px 8px  rgba(0, 0, 0, 0.2);
  }
  .rightdiv h4{
    /* color: rgb(40, 208, 191); */
    color: black;
    font-size: 13px;
  }
  .success-page h1{
    font-size: 18px;
    text-decoration: none;
    /* margin-top: 10px; */
    /* border-bottom: 1px solid rgb(182, 182, 182); */
  }
  /* .success-page{
    position: relative;
    top: 80px;
    margin: 0;
    padding: 0;
 
  } */
  .success-main{
    min-height: 60vh;
  }
  * {
    margin: 0;
    padding: 0;
    /* background-color: rgb(255, 181, 181); */
  }
  

}


@media only screen and (max-width: 700px) {
  
  .main {
    flex-wrap: wrap;
    margin-top: 70px;
    background-color: rgb(255, 255, 255);
    background-image: url("welcome-phone-4.png");
    height: 590px;
    align-items: flex-start;
    padding: 10px;
  }
  .welcome-note h1 {
    font-size: 22px;
    margin-top: 5px;
  }
  .welcome-note h2 {
    /* width: 100%; */
    font-size: 17px;
    margin: 10px;
    margin-top: 20px;
    /* background-color: #D8B5FF; */
  }
  .welcome-note p {
    font-size: 15px;
    margin: 20px 0px;
    line-height: 20px;
    text-align: justify;
  }
  .welcome-note {
    width: auto;
    padding: 10px;
    margin-left: 0px;
    /* height: 370px; */
    /* background-color: aqua; */
    /* width: 100%; */

  }

 


}







* {
  margin: 0;
  padding: 0;
  /* background-color: rgb(255, 181, 181); */
}
/* 
.nav{
  position: absolute;
  top: 0;
} */