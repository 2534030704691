/* Import Google font - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

.login-position {
  width: auto;
  height: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  padding-block-end: 50px;
  /* align-items: center; */
}

.login-heading {
  height: 70px;
  max-width: 380px;
  text-align: center;
  line-height: 1.3;
  font-weight: 600;
  /* background-color: #060b23; */
  background: -webkit-linear-gradient(#00f2ff, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* color: rgb(45, 255, 157); */
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(0, 145, 255);
  border-radius: 20px;
}

.cont2 {
  margin-top: 20px;
  padding: 10px 0px;
  font-family: Arial, Helvetica, sans-serif;
  /* background-color: #00bbbb; */
}


.login-box {
  z-index: 100;
  position: absolute;
  position: fixed;
  height: 410px;
  /* overflow: hidden; */
  /* width: 400px; */
  background-color: rgb(11, 8, 67);
  /* background-color: #000000; */
  padding: 15px;
  border: 1px solid rgb(36, 54, 255);
  /* margin-right: 50px; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* left: 35%; */
  /* margin: 100px; */
  margin-top: 0px;
  border-radius: 22px;
}

.forgot {
  text-decoration: none;
  margin-left: 26px;
  color: rgb(0, 255, 191);
}

#sub {
  height: 42px;
  padding: 10px;
 background-color: aqua;
  /* background-color: rgb(27, 27, 160); */
  border: none;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  font-size: 1.3em;
  width: 45%;
  margin: 10px;
  margin-top: 0px;
}
#sub:hover {
  transition: all 300ms;
  background-color: rgb(8, 8, 145);
  color: rgb(255, 255, 255);
}
.option {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn {
  height: 42px;
  border: 2px solid rgb(212, 212, 212);
 background: transparent;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  font-size: 1.2em;
  padding: 10px;
  width: 45%;
  border-radius: 22px;
  margin-top: 0px;
}
.btn:hover {
  border: none;
  transition: all 400ms;
  background-color: rgb(27, 27, 160);
  color: rgb(255, 255, 255);
}
.input-field {
  position: relative;
  margin: 10px;
  margin-bottom: 20px;
}
.btm {
  color: rgb(0, 0, 0);
  height: 30px;
  text-align: center;
}
.btm span {
  color: rgb(0, 213, 255);
  cursor: pointer;
}
.input-field input {
  width: 330px;
  height: 45px;
  border-radius: 6px;
  font-size: 18px;
  padding: 0 15px;
  border: 2px solid #47fdf1;
  /* border: 2px solid rgb(104, 104, 201); */
  background: transparent;
  color: rgb(255, 255, 255);
  /* color: #fff; */
  outline: none;
}
.input-field select{
  width: 364px;
  height: 45px;
  border-radius: 6px;
  font-size: 18px;
  padding: 0 15px;
  border: 2px solid #47fdf1;
  /* border: 2px solid rgb(104, 104, 201); */
  background: transparent;
  color: rgb(255, 255, 255);
  /* color: #fff; */
  outline: none;
}
.class-option{
 background-color: rgb(11, 8, 67);
 
}

.error{
  color: rgb(255, 86, 134);
  margin-left: 10px;
}
.input-field input::-webkit-outer-spin-button,
.input-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} 
.input-field label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: rgb(255, 255, 255);
  /* color: #fff; */
  font-size: 16px;
  pointer-events: none;
  transition: 0.3s;
}
input:focus {
  border: 2px solid rgb(255, 255, 255);
}
input:focus~label,
input:valid~label {
  top: 0;
  left: 15px;
  font-size: 13px;
  padding: 0 2px;
  background:rgb(6, 17, 73);
}

@media only screen and (max-width: 700px) {
  .input-field input {
    width: 250px;
    font-size: 16px;
    height: 39px;
    border: 1px solid rgb(103, 255, 235);
  }
  .input-field select{
    width: 280px;
    font-size: 16px;
    height: 39px;
    border: 1px solid rgb(103, 255, 235)
  }

  .login-heading {
    height: 50px;
    font-size: 20px;
    border-radius: 15px;
  }

  .input-field label {
    font-size: 14px;
  }

  input:focus~label,
  input:valid~label {
    font-size: 12px;
  }

  .login-box {
    padding: 20px;
    height: 350px;
    /* width: 300px; */
    /* left: 5%; */
    /* top: 200px; */
  }

  #sub {
    height: 40px;
    /* border: 1px solid rgb(109, 255, 255); */
    font-size: 1.1em;
    width: 45%;
    /* margin-top: 15px; */
  }

  .btn {
    height: 40px;
    border: 1px solid rgb(0, 225, 255);
    color: aqua;
    font-size: 1.1em;
    width: 45%;
    /* margin-top: 15px; */
  }

}



